<template>
  <house-profile-component @update-title="changeTitle" />
</template>

<script>
import HouseProfileComponent from "@/components/houses/HouseProfileComponent";

export default {
  name: "HouseProfile",
  title: "Perfil del Hostal | Baja California Health Tourism",
  components: { HouseProfileComponent },
  methods: {
    changeTitle(e) {
      this.title = e;
      document.title = `${e} | Baja California Health Tourism`;
    }
  }
};
</script>

<style scoped></style>
